// @flow
import React, {useCallback, useEffect, useState} from 'react';
import {Box, Text} from '@chakra-ui/react';
import COLORS from '../common/colors';
import {updatePotentialPatient} from "../api/capilots";
import withNavigation from "../main/WithRoutes";
import {useAuthenticator} from "@aws-amplify/ui-react";
import queryString from "query-string";
import AWS from "aws-sdk";
import {Auth} from "aws-amplify";
import {useCurrentUserContext} from "../context/CurrentUserContext";

type Props = {
  version: string,
  params: any,
  location: any
};

export function TestInfoUpdatePageImpl(props: Props) {
  const [message, setMessage] = useState("Updating your selection...");
  const [updated, setUpdated] = useState(false);
  const { user } = useAuthenticator((context) => [context.user]);
  const { currentPatientId } = useCurrentUserContext();

  const recordId = props.params.record;
  const queryParams = queryString.parse(props.location.search);
  const interested = queryParams.int === 'true';

  const signOut = useCallback(async () => {
    if (user) {
      console.log("signing out")
      if (AWS.config.credentials) {
        AWS.config.credentials.clearCachedId();
      }
      await Auth.signOut();
      sessionStorage.removeItem("handledClockOffset");
    }
  }, [user]);

  useEffect(() => {
    async function onSignOut() {
      await signOut();
    }
    async function onLoggedIn(isInterested) {
      await updatePotentialPatient(recordId, isInterested)
      setUpdated(true);
      await onSignOut();
    }
    if (!updated && user) {
      onLoggedIn(interested).then(() => {
        if (interested) {
          setMessage("Your doctor has been notified of your interest in genetic testing and you can discuss it further during your upcoming visit. Thank you!")
        } else {
          setMessage("Your selection has been updated, thanks!")
        }}
      ).catch((e) => {
        console.log("failed to update selection")
        console.log(e);
        onSignOut().then();
      })
    } else if (user) {
      setTimeout(()=> {
        if (user) {
          onSignOut().then();
        }
      }, 10000)
    }
  }, [user, currentPatientId]);  // eslint-disable-line


  return (
    <Box color={COLORS.REPORT_TEXT} mx="8%" mt="50px" >
      <Text mb={"10px"} color={"gray.600"}>{message}</Text>
    </Box>
  );
}

export default withNavigation(TestInfoUpdatePageImpl);
