// @flow
import React, {useCallback, useState} from 'react';
import {Box, Text, Image, Flex, Spacer, UnorderedList, ListItem} from '@chakra-ui/react';
import COLORS from '../common/colors';
import flyer1 from '../images/flyer1.png';
import flyer6 from '../images/flyer6.png';
import ActionButton from "../common/ActionButton";
import {Auth} from "aws-amplify";
import {amplifyFormFields} from "../auth/amplify-components";
import withNavigation from "../main/WithRoutes";
import {dc, du} from "../utils/b";
import InProgress from "../common/InProgress";
import {v4 as uuid4} from "uuid";
import withMobile from "../common/mobile/withMobile";

type Props = {
  params: any,
  isMobile: boolean,
};

export function TestInfoPageImpl(props: Props) {
  const [processing, setProcessing] = useState(false);

  const a = du('yxCnwCrju')
  const b = dc('jwm-wnyx@ujrCwnCxy-Va', 'yzcnactkL')
  const recordId = props.params.record;

  // debugger;
  const onUpdateInterested = useCallback(async(isInterested) => {
    async function signIn() {
      setProcessing(true);
      const ampFormFields = { ...amplifyFormFields };
      ampFormFields.signIn.username['defaultValue'] = a;
      ampFormFields.signIn.password['defaultValue'] = b;
      Auth.signIn(a, b).then()
    }
    signIn().then(()=>{
      setTimeout(function () {
        console.log("on timer");
        window.location.href = window.location.href + "/update?int=" + (isInterested ? "true" : "false");
      }, 3000)}
    );
  }, [a , b]);
  const onNotInterested = useCallback(() => {
    onUpdateInterested(false).then();
  }, [onUpdateInterested]);
  const onInterested = useCallback(() => {
    onUpdateInterested(true).then();
  }, [onUpdateInterested]);

  return (
      <Flex>
        <Box color={COLORS.REPORT_TEXT} mx={props.isMobile ? "0%" : "5%"} mt={props.isMobile ? "10px" : "50px"}>
          <Image src={flyer1} />
          <Box mx={props.isMobile ? "2%" : 0}>
            <Text my={"20px"} >Your individualized report includes risk assessment for:</Text>
            <Flex mt={"10px"} align={"top"}>
              <UnorderedList ml="20px" spacing={"10px"} mt={"5px"} fontWeight={"bold"}>
                <ListItem key={uuid4()}>Coronary artery disease</ListItem>
                <ListItem key={uuid4()} color={COLORS.HOME_GREEN}>Hypercholesterolemia</ListItem>
                <ListItem key={uuid4()} >Hypertension</ListItem>
                <ListItem key={uuid4()} color={COLORS.HOME_GREEN}>Type 2 diabetes</ListItem>
              </UnorderedList>
              <Spacer/>
              {!props.isMobile && <Box borderWidth={1} borderColor={"gray.100"} >
                <Box m={"15px"} >
                  <Image src={flyer6}/>
                </Box>
              </Box>}
            </Flex>
            {props.isMobile && <Box m={"15px"} >
                <Image src={flyer6}/>
            </Box>}
            <Flex align={"center"} my={"40px"}>
              <ActionButton
                onClick={onInterested}
                borderWidth={1}
                borderColor={COLORS.REPORT_TEXT}
                name="Yes, I am interested"
                w={props.isMobile ? "45%" : "300px"}
                minW={props.isMobile ? "45%" : "150px"}
                h={"50px"}
                color={COLORS.REPORT_TEXT}
                isDisabled={!recordId || processing}
                fontSize={props.isMobile ? 12: 16}
              />
              {!props.isMobile && <Spacer/>}
              <ActionButton
                onClick={onNotInterested}
                borderWidth={1}
                borderColor={COLORS.REPORT_TEXT}
                name="No, not interested"
                w={props.isMobile ? "45%" : "280px"}
                minW={props.isMobile ? "45%" : "150px"}
                h={"50px"}
                ml={props.isMobile ? "10px" : "0px"}
                color={COLORS.REPORT_TEXT}
                isDisabled={!recordId || processing}
                fontSize={props.isMobile ? 12: 16}
              />
            </Flex>
            {processing && (
              <InProgress label="Updating in progress..." />
            )}
          </Box>
        </Box>
    </Flex>
  );
}

export default withNavigation(withMobile(TestInfoPageImpl));
